.player-wrap {
    position: relative;
    background-color: #333;
    width:100%;
    height: 100%;
}
.mute-button {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-46px, 0px);
    z-index: 999;
    visibility: hidden;
}
.player-wrap:hover .mute-button {
    visibility: visible;
}
.player-wrap:hover .full-screen {
    visibility: visible;
}
.full-screen {
    position: absolute;
    bottom: 20px;
    right: 5%;
    visibility: hidden;
    z-index: 999;
    cursor: pointer;
}

@media screen and (max-width: 600px) {
  .info-main {
      display: none;
  }
  #video {
      height: auto !important;
  }
  .player-wrap {
        video {
            height: auto !important;
        }
    }
}

