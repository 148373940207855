@function get-vw($target) {
  $vw-context: (1920 * 0.01) * 1px;
  @return ($target/$vw-context) * 1vw;
}

.header-container {
  font-size: get-vw(16px);
  position: relative;
  left: 0;
  top: 0;
  height: get-vw(350px);
  width: 100%;

  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .img-text {
    width: 80%;
    height: get-vw(50px);
    position: absolute;
    left: 10%;
    top: 20%;
  }

  .blue-bg {
    width: 100%;
    height: get-vw(120px);
    position: absolute;
    left: 0;
    top: 45%;
  }

  .button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: get-vw(108px);
    justify-content: center;
    position: absolute;
    top: 47%;

    a {
      height: 100%;
      width: 10%;
      margin-right: get-vw(50px);
      position: relative;
      top: 0px;
      padding: 0 !important;

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
      }
    }
  }
}

@media screen and (min-device-width:701px) and (max-device-width: 1000px) {
  .tab-container {
    height: get-vw(550px);
    .img-text{
      width: 80%;
      height: get-vw(50px);
      position: absolute;
      left: 10%;
      top: 20%;
    }

    .blue-bg{
      height: get-vw(220px);
      position: absolute;
      left: 0;
      top: 40%;
    }
    .button-container {
      height: get-vw(208px);
      top: 44%;
      a {
        height: 100%;
        width: 18%;
        margin-right: get-vw(50px);
        position: relative;
        top: 0;
        padding: 0 !important;
      }
    }
  }
}

@media screen and (max-device-width: 700px) {
  .tab-container {
    height: get-vw(350px);
    font-size: get-vw(16px);
    .img-text{
      width: 80%;
      height: get-vw(50px);
      position: absolute;
      left: 10%;
      top: 20%;
    }
    
    .blue-bg{
      height: get-vw(220px);
      position: absolute;
      left: 0;
      top: 40%;
    }
    .button-container {
      height: get-vw(208px);
      top: 44%;
      a {
        height: 100%;
        width: 18%;
        margin-right: get-vw(50px);
        position: relative;
        top: 0;
        padding: 0 !important;
      }
    }
  }
}